<template>
  <div class="box">
    <div class="echarts" :id="'echarts-' + index"></div>
    <div style="text-align: center; font-size: 14px">
      {{ hasMore ? "左滑加载更多数据" : "没有更多" }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myChart: null,
      updateLength: 0,
      showLength: 10,
    };
  },
  props: {
    historyList: {
      type: Array,
      default: null,
    },
    index: {
      type: String,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  watch: {
    "historyList.length": {
      handler(newVal, oldVal) {
        let that = this;
        // console.log(newVal, oldVal);
        let option = {
          xAxis: {
            type: "category",
            data: this.historyList.map((m) => m.x),
            axisLabel: {
              width: 50,
              overflow: "break",
              interval: 1,
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
          yAxis: {
            type: "value",
          },
          tooltip: {
            //提示框组件，用于配置鼠标滑过或点击图表时的显示框。
            show: true, // 是否显示
            trigger: "axis", // 触发类型  'item'图形触发：散点图，饼图等无类目轴的图表中使用； 'axis'坐标轴触发；'none'：什么都不触发。
            axisPointer: {
              // 坐标轴指示器配置项。
              type: "line", // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
            },
            backgroundColor: "rgba(50,50,50,0.7)", // 提示框浮层的背景颜色。
            borderColor: "#333", // 提示框浮层的边框颜色。
            borderWidth: 0, // 提示框浮层的边框宽。
            padding: 5, // 提示框浮层内边距，
            textStyle: {
              // 提示框浮层的文本样式。
              color: "#fff",
              fontStyle: "normal",
              fontWeight: "normal",
              fontFamily: "sans-serif",
              fontSize: 14,
            },
            formatter: function (data) {
              return data[0].data + that.unit;
            },
          },
          dataZoom: [
            {
              type: "slider",
              start: Number(
                (((newVal - oldVal) / this.historyList.length) * 100).toFixed(1)
              ),
              end:
                Number(
                  (((newVal - oldVal) / this.historyList.length) * 100).toFixed(
                    1
                  )
                ) +
                Number(
                  ((that.showLength / this.historyList.length) * 100).toFixed(1)
                ),
              zoomLock: true,
              brushSelect: false,
            },
          ],
          series: [
            {
              data: this.historyList.map((m) => Number(m.y.toFixed(2))),
              type: "line",
              smooth: true,
              label: {
                show: true,
                distance: -26,
                position: "top",
                textStyle: {
                  fontSize: 14,
                },
              },
            },
          ],
        };
        this.myChart.setOption(option);
      },
    },
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      let that = this;
      this.myChart = this.$echarts.init(
        document.getElementById("echarts-" + this.index)
      );
      let option = {
        title: {
          text: that.name,
          subtext:'('+that.unit+')',
          show: true,
          x:'center',
          textStyle:{
            padding:'20px 0 0'
          }
        },
        xAxis: {
          type: "category",
          data: this.historyList.map((m) => m.x),
          axisLabel: {
            width: 50,
            overflow: "break",
            interval: 1,
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
        },
        dataZoom: [
          {
            type: "slider",
            start:
              100 -
              Number(
                ((that.showLength / this.historyList.length) * 100).toFixed(1)
              ),
            end: 100,
            zoomLock: true,
            brushSelect: false,
          },
        ],
        tooltip: {
          //提示框组件，用于配置鼠标滑过或点击图表时的显示框。
          show: true, // 是否显示
          trigger: "axis", // 触发类型  'item'图形触发：散点图，饼图等无类目轴的图表中使用； 'axis'坐标轴触发；'none'：什么都不触发。
          axisPointer: {
            // 坐标轴指示器配置项。
            type: "cross", // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
          },
          backgroundColor: "rgba(50,50,50,0.7)", // 提示框浮层的背景颜色。
          borderColor: "#333", // 提示框浮层的边框颜色。
          borderWidth: 0, // 提示框浮层的边框宽。
          padding: 5, // 提示框浮层内边距，
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 14,
          },
          formatter: function (data) {
            return data[0].data + that.unit;
            // return arg[0].name + "的分数是:" + arg[0].data;
          },
        },
        series: [
          {
            data: this.historyList.map((m) => Number(m.y.toFixed(2))),
            type: "line",
            smooth: true,
            label: {
              show: true,
              distance: -26,
              position: "top",
              textStyle: {
                fontSize: 14,
              },
            },
          },
        ],
      };
      this.myChart.setOption(option);
      this.myChart.on("dataZoom", (params) => {
        if (params.start === 0) {
          //   console.log("刷新");
          this.$emit("pageChange");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 800px;
  height: 300px;
}
.box {
  background-color: #fff;
  border-radius: 10px;
}
</style>