<template>
  <div>
    <div class="custom-steps">
      <div class="left-steps">
        <el-steps :align-center="true">
          <el-step v-for="(d, i) in topSteps" :key="i">
            <template slot="icon">
              <div style="font-size: 36px">
                <div
                  v-if="d.active"
                  class="activated"
                  :class="{ pointer: d.isCcp || (!isCcp && d.active) }"
                  @click="toTrace(d)"
                >
                  <i class="el-icon-success"></i>
                </div>
                <div v-else>
                  <i class="el-icon-error"></i>
                </div>
              </div>
            </template>
            <template slot="title">
              <div
                :class="{
                  activated: d.active,
                  ccp: d.isCcp,
                  pointer: d.active,
                  default: !d.active,
                }"
                style="padding: 5px 0"
                @click="toTrace(d)"
              >
                {{ d.name }}
              </div>
            </template>
            <template slot="description">
              <div
                :class="{
                  activated: d.active,
                  ccp: d.isCcp,
                  pointer: d.active,
                }"
                class="default"
              >
                {{ d.isCcp ? "含Ccp点" : d.active ? "已完成" : "未完成" }}
              </div>
            </template>
          </el-step>
        </el-steps>
        <div class="left-steps-bottom">
          <el-steps
            v-for="(item, index) in bottomSteps"
            :key="index"
            align-center
          >
            <el-step v-for="(d, i) in item" :key="i">
              <template slot="icon">
                <div style="font-size: 36px">
                  <div v-if="d.active" class="activated">
                    <i class="el-icon-success"></i>
                  </div>
                  <div v-else>
                    <i class="el-icon-error"></i>
                  </div>
                </div>
              </template>
              <template slot="title">
                <div
                  :class="{ activated: d.active, ccp: d.isCcp }"
                  style="padding: 5px 0"
                >
                  {{ d.name }}
                </div>
              </template>
              <template slot="description">
                <div
                  :class="{
                    activated: d.active,
                    ccp: d.isCcp,
                  }"
                  class="default"
                >
                  {{ d.isCcp ? "含Ccp点" : d.active ? "已完成" : "未完成" }}
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
      </div>

      <div
        v-for="(item, index) in midSteps"
        :key="index"
        :style="{ marginTop: 26 + index * 120 + 'px' }"
        :class="{
          'right-steps': index === 0,
          'pa-right': index % 2 === 0 && index != 0,
          pa: index % 2 !== 0,
        }"
        v-show="midSteps.length > 0"
      >
        <div
          :class="{ circle: index % 2 === 0, 'circle-left': index % 2 !== 0 }"
        >
          <div
            class="icon"
            style="font-size: 36px"
            :class="{ activated: item.active }"
          >
            <i v-if="item.active" class="el-icon-success"></i>
            <i v-else class="el-icon-error"></i>
            <span class="tips">
              <div
                class="f16"
                :class="{ activated: item.active, ccp: item.isCcp }"
              >
                {{ item.name }}
              </div>
              <div
                :class="{
                  activated: item.active,
                  ccp: item.isCcp,
                }"
                class="default"
              >
                {{ item.isCcp ? "含Ccp点" : item.active ? "已完成" : "未完成" }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="t-dialog" id="fade-box" v-show="showInfo">
      <div class="p50 record-bg">
        <!-- <el-collapse v-model="activeName" accordion> -->
        <div v-for="(item, index) in traceList" :key="index" class="df pr">
          <div class="flex-8 tc col-fff f20 fw-b">
            <h1>{{ item.name }}</h1>
            <div
              v-if="item.ccpRemark"
              style="padding: 10px 20px; font-size: 14px"
            >
              关键限值:{{ item.ccpRemark }}
            </div>
          </div>
          <div class="flex-16 tl">
            <div v-if="item.type == 2" class="col-fff">
              <h1>{{ item.content }}</h1>
            </div>
            <div v-else-if="item.type == 1" class="">
              <el-image
                v-for="(src, srcIndex) in JSON.parse(item.content)"
                :key="srcIndex"
                style="width: 200px; height: 200px"
                :src="src.url"
                fit="cover"
                :preview-src-list="[src.url]"
                class="mr20 t-border mb10"
              ></el-image>
            </div>
            <div v-else-if="item.type == 6" class="">
              <el-image
                v-for="(src, srcIndex) in JSON.parse(item.content)"
                :key="srcIndex"
                style="width: 200px; height: 200px"
                :src="src.imgUrl"
                fit="cover"
                :preview-src-list="[src.imgUrl]"
                class="mr20 t-border mb10"
              ></el-image>
            </div>
            <div
              v-else-if="(item.type == 4 || item.type == 3) && showInfo"
              class="flex-16 tl"
            >
              <div
                v-for="(vidoe, vidoeIndex) in JSON.parse(item.content)"
                :key="vidoeIndex"
              >
                <video
                  Controls
                  :poster="vidoe + '?x-oss-process=video/snapshot,t_1,m_fast'"
                  class="video-js t-border mb10"
                  style="height: 260px; width: 100%; margin-bottom: 5px"
                >
                  <source :src="vidoe" type="video/mp4" />
                </video>
              </div>
            </div>
            <div v-else-if="item.type == 8">
              <div
                v-loading="item.loading"
                style="
                  width: 800px;
                  height: 320px;
                  border-radius: 10px;
                  margin-bottom: 10px;
                "
              >
                <CcpCharts
                  :name="item.name"
                  :historyList="item.historyData"
                  v-if="item.initFlag"
                  :hasMore="item.hasMore"
                  :unit="item.data.unit"
                  :index="item.id + '-' + index"
                  @pageChange="pageChange(item)"
                />
              </div>
            </div>
            <div v-else-if="item.type == 7">
              <div
                v-for="sensorItem in item.content"
                :key="sensorItem.id"
                v-loading="sensorItem.loading"
                style="
                  width: 800px;
                  height: 320px;
                  margin-bottom: 10px;
                  border-radius: 10px;
                "
              >
                <CcpCharts
                  :name="item.name"
                  :historyList="sensorItem.historyData"
                  :index="sensorItem.id + '-' + index"
                  :hasMore="sensorItem.hasMore"
                  :unit="sensorItem.data.unit"
                  v-if="sensorItem.initFlag"
                  @pageChange="pageChange(sensorItem)"
                />
              </div>
            </div>
            <div
              v-if="!item.isCollapse && isCcp"
              class="pointer"
              @click="openCollapse(item)"
              style="
                text-align: center;
                padding:10px
                width: 100%;
                background-color: #EDEDED;
                border-radius: 10px;
                color:#409EFF;
              "
            >
              纠偏记录<i
                class="el-icon-arrow-down"
                style="margin-left: 5px; color: #409eff"
              ></i>
            </div>
            <div
              v-if="item.isCollapse && isCcp"
              style="
                padding: 10px;
                width: 100%;
                background-color: #ededed;
                border-radius: 10px;
              "
            >
              <div>
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="item.form.text"
                >
                </el-input>
                <div class="df aic" style="flex-wrap: wrap">
                  <template v-if="item.form && item.form.imgs">
                    <div
                      v-for="(v, i) in item.form.imgs"
                      :key="'img' + i"
                      style="width: 100px; margin: 0 5px"
                    >
                      <div
                        style="
                          width: 80px;
                          height: 80px;
                          margin: 10px;
                          position: relative;
                        "
                        class="mask-container"
                      >
                        <div class="mask-box df aic jcc">
                          <i
                            class="el-icon-zoom-in pointer"
                            style="color: #fff"
                            @click="handlePreview(v.url)"
                          ></i>
                          <div style="width: 20px; height: 20px"></div>
                          <i
                            class="el-icon-delete pointer"
                            style="color: #fff"
                            @click="handleImgRemove(item, i)"
                          ></i>
                          <!-- el-icon-delete -->
                        </div>
                        <!-- <img
                          :src="v.url"
                          style="width: 100%; height: 100%; object-fit: cover"
                        /> -->
                        <el-image
                          :src="v.url"
                          :preview-src-list="[v.url]"
                          style="width: 100%; height: 100%; object-fit: cover"
                        >
                        </el-image>
                      </div>

                      <div
                        class="df aic"
                        style="
                          flex-wrap: wrap;
                          overflow: hidden;
                          max-height: 40px;
                          font-size: 14px;
                        "
                      >
                        {{ v.name }}
                      </div>
                    </div>
                  </template>
                  <template v-if="item.form && item.form.videos">
                    <div
                      v-for="(v, i) in item.form.videos"
                      :key="'video' + i"
                      style="width: 100px; margin: 0 5px"
                    >
                      <div
                        style="
                          width: 80px;
                          height: 80px;
                          position: relative;
                          margin: 10px;
                        "
                        class="mask-container"
                      >
                        <div class="mask-box df aic jcc">
                          <i
                            class="el-icon-zoom-in pointer"
                            style="color: #fff"
                            @click="handlePreview(v.url)"
                          ></i>
                          <div style="width: 20px; height: 20px"></div>
                          <i
                            class="el-icon-delete pointer"
                            style="color: #fff"
                            @click="handleVideoRemove(item, i)"
                          ></i>
                        </div>
                        <img
                          src="@/assets/img/video.png"
                          style="width: 100%; height: 100%; object-fit: cover"
                        />
                        <!-- position: relative; -->
                      </div>

                      <div
                        class="df aic"
                        style="
                          flex-wrap: wrap;
                          overflow: hidden;
                          max-height: 40px;
                          font-size: 14px;
                        "
                      >
                        {{ v.name }}
                      </div>
                    </div>
                  </template>
                </div>

                <div
                  class="df aic"
                  style="justify-content: flex-end; margin-top: 10px"
                >
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :show-file-list="false"
                    :http-request="
                      (params) => {
                        addImg(params, item);
                      }
                    "
                    multiple
                  >
                    <img
                      class="pointer"
                      src="@/assets/img/image.png"
                      style="width: 20px; height: 20px; object-fit: cover"
                    />
                  </el-upload>
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :show-file-list="false"
                    :http-request="
                      (params) => {
                        addVideo(params, item);
                      }
                    "
                    multiple
                  >
                    <img
                      class="pointer"
                      src="@/assets/img/video.png"
                      style="
                        width: 20px;
                        height: 20px;
                        object-fit: cover;
                        margin-left: 20px;
                      "
                    />
                  </el-upload>

                  <el-button
                    @click="createCorrect(item)"
                    size="mini"
                    type="primary"
                    style="margin-left: 20px"
                    >新增纠偏</el-button
                  >
                </div>
              </div>
              <div
                v-for="c in item.correctList"
                :key="c.id"
                style="
                  background-color: #fff;
                  border-radius: 10px;
                  margin-top: 10px;
                "
              >
                <div
                  style="
                    height: 40px;
                    padding: 0px 10px 0 20px;
                    justify-content: flex-end;
                  "
                  class="df aic"
                >
                  <el-popover placement="bottom" width="100px" trigger="hover">
                    <div
                      class="more-btn df aic jcc pointer"
                      style="color: #409eff"
                      @click="c.isEdit = true"
                    >
                      修改
                    </div>
                    <div
                      class="more-btn df aic jcc pointer"
                      style="color: #f56c6c; margin-top: 5px"
                      @click="delCorrect(item, c)"
                    >
                      删除
                    </div>
                    <div class="more-box" slot="reference">
                      <i class="el-icon-more"></i>
                    </div>
                  </el-popover>
                </div>
                <div style="padding: 0px 30px" v-if="!c.isEdit">
                  {{ c.txtInfo }}
                </div>
                <div v-else style="padding: 10px">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="c.txtInfo"
                  ></el-input>
                </div>
                <div
                  v-if="!c.isEdit"
                  class="df aic"
                  style="
                    flex-wrap: wrap;
                    margin-bottom: 10px;
                    padding: 0px 10px;
                  "
                >
                  <template v-if="c.imgInfo">
                    <div
                      v-for="(v, i) in JSON.parse(c.imgInfo)"
                      :key="'img' + i"
                      class="pointer"
                      style="width: 120px; margin: 0 5px"
                    >
                      <div style="width: 100px; height: 100px; margin: 10px">
                        <!-- <img
                          :src="v.url"
                          @click="handlePreview(v.url)"
                          style="width: 100%; height: 100%; object-fit: cover"
                        /> -->
                        <el-image
                          :src="v.url"
                          :preview-src-list="[v.url]"
                          style="width: 100%; height: 100%; object-fit: cover"
                        >
                        </el-image>
                      </div>

                      <!-- <div
                        class="df aic"
                        style="
                          flex-wrap: wrap;
                          overflow: hidden;
                          max-height: 40px;
                          font-size: 14px;
                        "
                      >
                        {{ v.name }}
                      </div> -->
                    </div>
                  </template>
                  <template v-if="c.videoInfo">
                    <div
                      v-for="(v, i) in JSON.parse(c.videoInfo)"
                      :key="'video' + i"
                      class="pointer"
                      style="width: 120px; margin: 0 5px"
                    >
                      <img
                        @click="handlePreview(v.url)"
                        src="@/assets/img/video-play.png"
                        style="
                          width: 100px;
                          height: 100px;
                          object-fit: cover;
                          margin: 10px;
                        "
                      />
                      <!-- <div
                        class="df aic"
                        style="
                          flex-wrap: wrap;
                          overflow: hidden;
                          max-height: 40px;
                          font-size: 14px;
                        "
                      >
                        {{ v.name }}
                      </div> -->
                    </div>
                  </template>
                </div>
                <div v-else style="padding: 10px">
                  <div class="df aic" style="flex-wrap: wrap">
                    <template v-if="c.imgInfo && JSON.parse(c.imgInfo)">
                      <div
                        v-for="(v, i) in JSON.parse(c.imgInfo)"
                        :key="'img' + i"
                        style="width: 100px; margin: 0 5px"
                      >
                        <div
                          style="
                            width: 80px;
                            height: 80px;
                            margin: 10px;
                            position: relative;
                          "
                          class="mask-container"
                        >
                          <div class="mask-box df aic jcc">
                            <i
                              class="el-icon-zoom-in pointer"
                              style="color: #fff"
                              @click="handlePreview(v.url)"
                            ></i>
                            <div style="width: 20px; height: 20px"></div>
                            <i
                              class="el-icon-delete pointer"
                              style="color: #fff"
                              @click="handleEditImgRemove(c, i)"
                            ></i>
                          </div>

                          <!-- <img
                            :src="v.url"
                            style="width: 100%; height: 100%; object-fit: cover"
                          /> -->
                          <el-image
                            :src="v.url"
                            :preview-src-list="[v.url]"
                            style="width: 100%; height: 100%; object-fit: cover"
                          >
                          </el-image>
                        </div>

                        <div
                          class="df aic"
                          style="
                            flex-wrap: wrap;
                            overflow: hidden;
                            max-height: 40px;
                            font-size: 14px;
                          "
                        >
                          {{ v.name }}
                        </div>
                      </div>
                    </template>
                    <template
                      v-if="c.videoInfo && JSON.parse(c.videoInfo).length"
                    >
                      <div
                        v-for="(v, i) in JSON.parse(c.videoInfo)"
                        :key="'video' + i"
                        style="width: 100px; margin: 0 5px"
                      >
                        <div
                          class="mask-container"
                          style="
                            width: 80px;
                            height: 80px;
                            object-fit: cover;
                            margin: 10px;
                            position: relative;
                          "
                        >
                          <div class="mask-box df aic jcc">
                            <i
                              class="el-icon-zoom-in pointer"
                              style="color: #fff"
                              @click="handlePreview(v.url)"
                            ></i>
                            <div style="width: 20px; height: 20px"></div>
                            <i
                              class="el-icon-delete pointer"
                              style="color: #fff"
                              @click="handleEditVideoRemove(c, i)"
                            ></i>
                          </div>
                          <img
                            src="@/assets/img/video.png"
                            style="width: 100%; height: 100%"
                          />
                        </div>

                        <div
                          class="df aic"
                          style="
                            flex-wrap: wrap;
                            overflow: hidden;
                            max-height: 40px;
                            font-size: 14px;
                          "
                        >
                          {{ v.name }}
                        </div>
                      </div>
                    </template>
                  </div>
                  <div
                    class="df aic"
                    style="justify-content: flex-end; margin-top: 10px"
                  >
                    <el-upload
                      class="upload-demo"
                      action="#"
                      :show-file-list="false"
                      :http-request="
                        (params) => {
                          editAddImg(params, c);
                        }
                      "
                      multiple
                    >
                      <img
                        class="pointer"
                        src="@/assets/img/image.png"
                        style="width: 20px; height: 20px; object-fit: cover"
                      />
                    </el-upload>
                    <el-upload
                      class="upload-demo"
                      action="#"
                      :show-file-list="false"
                      :http-request="
                        (params) => {
                          editAddVideo(params, c);
                        }
                      "
                      multiple
                    >
                      <img
                        class="pointer"
                        src="@/assets/img/video.png"
                        style="
                          width: 20px;
                          height: 20px;
                          object-fit: cover;
                          margin-left: 20px;
                        "
                      />
                    </el-upload>

                    <el-button
                      @click="editCorrect(item, c)"
                      size="mini"
                      type="success"
                      style="margin-left: 20px"
                      >修改</el-button
                    >
                  </div>
                </div>
                <div class="df aic jcb" style="padding: 5px 20px">
                  <div style="color: #bbb">
                    纠偏人:{{ c.updatedName ? c.updatedName : c.createdName }}
                  </div>
                  <div style="font-size: 14px; color: #bbb">
                    {{
                      c.updatedTime
                        ? c.updatedTime.replace("T", " ")
                        : c.createdTime.replace("T", " ")
                    }}
                  </div>
                </div>
              </div>
              <div
                class="df aic"
                style="justify-content: flex-end; margin-top: 10px"
                v-if="item.correctListTotal"
              >
                <el-pagination
                  background
                  :page-size="5"
                  layout="total,prev, pager, next"
                  :current-page="item.correctQuery.page"
                  @current-change="(page) => handleCorrect(page, item)"
                  :total="item.correctListTotal"
                >
                </el-pagination>
              </div>
              <div
                class="pointer"
                style="width: 100%; text-align: center; color: #409eff"
                @click="item.isCollapse = !item.isCollapse"
              >
                折叠<i
                  class="el-icon-arrow-up"
                  style="margin-left: 5px; color: #409eff"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="t-close" @click="closeTrace">×</div>
        <div class="t-title">工序记录</div>
        <!-- </el-collapse> -->
      </div>
    </div>
  </div>
</template>

<script>
import api from "../util/extra-api";
import CcpCharts from "./CcpCharts.vue";
import { local } from "@/util/util";
import axios from "@/util/api";

export default {
  name: "CustomStep",
  components: {
    CcpCharts,
  },
  props: {
    stepsData: {
      type: Array,
      default() {
        return [];
      },
    },
    companyId: {
      type: Number,
      default() {
        return 0;
      },
    },
    haccpType: {
      type: Number,
      default() {
        return 0;
      },
    },
    finishedTag: {
      type: String,
    },
    isCcp: {
      type: Boolean,
    },
  },
  data() {
    return {
      topSteps: [],
      midSteps: [],
      bottomSteps: [[], [], [], [], [], []],
      midFlag: false,
      midNum: 0,
      showInfo: false,
      traceList: [],
      activeName: null,
    };
  },
  watch: {},
  mounted() {
    this.initStepsData();
  },
  methods: {
    editCorrect(item, data) {
      let parmas = {
        id: data.id,
        companyId: this.companyId,
        ccpId: item.id,
      };
      if (data.txtInfo) {
        parmas.txtInfo = data.txtInfo;
      }
      if (data.imgInfo) {
        parmas.imgInfo = data.imgInfo;
      }
      if (data.videoInfo) {
        parmas.videoInfo = data.videoInfo;
      }
      if (!data.txtInfo && !data.imgInfo && !data.videoInfo) {
        this.$message.error("请正确输入/上传纠偏信息");
        return;
      }
      api.post("/v1/pc/haccpScreen/updCorrect", parmas).then((res) => {
        if (res.code === 200) {
          this.getCorrectList(item.correctQuery, item);
        }
      });
    },
    editAddImg(params, data) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          let imgs = null;
          if (data.imgInfo && JSON.parse(data.imgInfo).length) {
            imgs = JSON.parse(data.imgInfo);
          } else {
            imgs = [];
          }
          imgs.push({
            name: resp.name,
            url: resp.url,
          });
          this.$set(data, "imgInfo", JSON.stringify(imgs));
          // console.log(data);
        }
      });
    },
    editAddVideo(params, data) {
      const file = params.file,
        fileType = file.type,
        isVidoe = fileType.includes("video");
      // console.log(file, isVidoe);
      if (!isVidoe) {
        this.$message.error("只能上传视频格式!");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("视频上传失败，请重新上传");
        } else {
          let videos = null;
          if (data.videoInfo && JSON.parse(data.videoInfo).length) {
            videos = JSON.parse(data.videoInfo);
          } else {
            videos = [];
          }
          videos.push({
            name: resp.name,
            url: resp.url,
          });
          this.$set(data, "videoInfo", JSON.stringify(videos));
        }
      });
    },
    delCorrect(item, data) {
      api.post("/v1/pc/haccpScreen/delCorrect", { id: data.id }).then((res) => {
        if (res.code === 200) {
          if (item.correctQuery.page !== 1 && item.correctList.length === 1) {
            item.correctQuery.page--;
            this.getCorrectList(item.correctQuery, item);
          } else {
            this.getCorrectList(item.correctQuery, item);
          }
        }
      });
    },
    handleCorrect(page, data) {
      this.$set(data.correctQuery, "page", page);
      // item.correctQuery.page = page;
      this.getCorrectList(data.correctQuery, data);
    },
    createCorrect(data) {
      // console.log(data);
      if (
        data.form.text ||
        (data.form.imgs && data.form.imgs.length) ||
        (data.form.videos && data.form.videos.length)
      ) {
        let parmas = {
          ccpId: data.id,
          companyId: this.companyId,
        };
        if (data.form.text) {
          parmas.txtInfo = data.form.text;
        }
        if (data.form.imgs && data.form.imgs.length) {
          parmas.imgInfo = JSON.stringify(data.form.imgs);
        }
        if (data.form.videos && data.form.videos.length) {
          parmas.videoInfo = JSON.stringify(data.form.videos);
        }
        api.post("/v1/pc/haccpScreen/correct", parmas).then((res) => {
          if (res.code === 200) {
            this.$set(data, "form", {
              text: "",
              imgs: [],
              videos: [],
            });
            this.getCorrectList(data.correctQuery, data);
          }
        });
      } else {
        this.$message.error("请正确输入/上传纠偏信息");
      }
    },
    addImg(params, data) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          let imgs = null;
          if (data.form && data.form.imgs) {
            imgs = data.form.imgs;
          } else {
            imgs = [];
          }
          imgs.push({
            name: resp.name,
            url: resp.url,
          });
          if (!data.form) {
            this.$set(data, "form", { imgs });
          } else {
            // console.log(data.form);
            this.$set(data.form, "imgs", imgs);
          }
          // console.log(data);
        }
      });
    },
    handleImgRemove(data, i) {
      console.log(data, i, "图片");
      this.$set(
        data.form,
        "imgs",
        data.form.imgs.filter((item, index) => index !== i)
      );
    },
    handleEditImgRemove(data, i) {
      console.log(data, i, "图片");
      this.$set(
        data,
        "imgInfo",
        JSON.stringify(
          JSON.parse(data.imgInfo).filter((item, index) => index !== i)
        )
      );
    },
    addVideo(params, data) {
      const file = params.file,
        fileType = file.type,
        isVidoe = fileType.includes("video");
      // console.log(file, isVidoe);
      if (!isVidoe) {
        this.$message.error("只能上传视频格式!");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("视频上传失败，请重新上传");
        } else {
          // console.log(resp.data);
          let videos = null;
          if (data.form && data.form.videos) {
            videos = data.form.videos;
          } else {
            videos = [];
          }
          videos.push({
            name: resp.name,
            url: resp.url,
          });
          if (!data.form) {
            this.$set(data, "form", { videos });
          } else {
            // console.log(data.form);
            this.$set(data.form, "videos", videos);
          }
          // console.log(data);
        }
      });
    },
    handleVideoRemove(data, i) {
      console.log(data, i, "视频");
      this.$set(
        data.form,
        "videos",
        data.form.videos.filter((item, index) => index !== i)
      );
    },
    handleEditVideoRemove(data, i) {
      console.log(data, i, "视频");
      this.$set(
        data,
        "videoInfo",
        JSON.stringify(
          JSON.parse(data.videoInfo).filter((item, index) => index !== i)
        )
      );
    },
    handlePreview(url) {
      window.open(url);
    },
    openCollapse(item) {
      let query = {
        ccpId: item.id,
        companyId: this.companyId,
        page: 1,
        limit: 5,
      };
      this.$set(item, "correctQuery", query);
      this.getCorrectList(query, item);
    },
    getCorrectList(query, data) {
      api.get("/v1/pc/haccpScreen/correctList", query).then((res) => {
        if (res.code === 200) {
          // console.log(res.data);
          if (!data.isCollapse) {
            data.isCollapse = true;
            this.$set(
              data,
              "correctList",
              res.data.map((v) => {
                return {
                  ...v,
                  isEdit: false,
                };
              })
            );
            this.$set(data, "correctListTotal", res.total);
          } else {
            this.$set(data, "correctList", res.data);
            this.$set(data, "correctListTotal", res.total);
          }
        }
      });
    },
    pageChange(sensorItem) {
      if (sensorItem.historyData.length < sensorItem.total) {
        sensorItem.query.page++;
        if (sensorItem.query.type) {
          api
            .get("/v1/wx/monitor/localSensorData", sensorItem.query)
            .then((res) => {
              if (res.code === 200) {
                // console.log(res.data.data);
                this.$set(
                  sensorItem,
                  "historyData",
                  res.data.data[0].historyData
                    .reverse()
                    .concat(sensorItem.historyData)
                );
                sensorItem.total = res.data.total;
                this.$set(
                  sensorItem,
                  "hasMore",
                  sensorItem.historyData.length !== sensorItem.total
                );
              }
            });
        } else {
          api.get("/v1/wx/monitor/sensorData", sensorItem.query).then((res) => {
            if (res.code === 200) {
              this.$set(
                sensorItem,
                "historyData",
                res.data.historyData.concat(sensorItem.historyData)
              );
              this.$set(
                sensorItem,
                "hasMore",
                sensorItem.historyData.length !== sensorItem.total
              );

              sensorItem.total = res.data.total;
            }
          });
        }
      }
    },
    toTrace(d) {
      if ((d.isCcp && d.active) || (d.active && !this.isCcp)) {
        let data;
        if (this.haccpType === 1) {
          data = {
            cateringHaccpId: d.recordId,
            companyId: this.companyId,
            haccpTemplateId: d.id,
          };
        } else {
          data = {
            productionRecordId: d.recordId,
            companyId: this.companyId,
            haccpTemplateId: d.id,
          };
        }
        if (this.finishedTag || this.finishedTag === "0") {
          data.finishedTag = this.finishedTag;
        }
        console.log(d, "结果", this.finishedTag, this.isCcp);
        api.get("/v1/pc/haccpScreen/submitCcpData", data).then((res) => {
          if (res.code === 200) {
            this.traceList = res.data;
            this.traceList.forEach((v) => {
              this.$set(v, "isCollapse", false);
              this.$set(v, "form", { text: "" });
              if (v.type === 8) {
                this.$set(v, "loading", true);
                this.$set(v, "initFlag", false);

                v.query = {
                  page: 1,
                  limit: 15,
                  id: v.id,
                  type: "task_haccp_sensor",
                };
                v.total = 0;
                api
                  .get("/v1/wx/monitor/localSensorData", v.query)
                  .then((res) => {
                    if (res.code === 200) {
                      v.total = res.data.total;
                      v.data = res.data.data[0];
                      this.$set(
                        v,
                        "historyData",
                        res.data.data[0].historyData.reverse()
                      );
                      v.option = null;
                      this.$set(v, "hasMore", v.historyData.length !== v.total);
                      this.$set(v, "initFlag", true);
                      this.$set(v, "loading", false);
                    }
                  });
              } else if (v.type === 7) {
                v.content = JSON.parse(v.content);

                v.content.forEach((senserItem) => {
                  this.$set(senserItem, "loading", true);
                  this.$set(senserItem, "initFlag", false);
                  senserItem.query = {
                    page: 1,
                    limit: 15,
                    id: senserItem.id,
                  };
                  senserItem.total = 0;

                  api
                    .get("/v1/wx/monitor/sensorData", senserItem.query)
                    .then((res) => {
                      if (res.code === 200) {
                        // console.log(res);
                        senserItem.total = res.data.total;
                        senserItem.data = res.data;
                        this.$set(
                          senserItem,
                          "historyData",
                          res.data.historyData
                        );
                        this.$set(
                          senserItem,
                          "hasMore",
                          senserItem.historyData.length !== senserItem.total
                        );

                        this.$set(senserItem, "initFlag", true);
                        this.$set(senserItem, "loading", false);
                      }
                    });
                });
              }
            });
            // console.log(this.traceList);
            this.showInfo = true;
          }
        });
      }
    },
    closeTrace() {
      this.showInfo = false;
    },
    initStepsData() {
      const stepsData = this.stepsData;
      if (stepsData && stepsData.length > 0 && stepsData.length <= 11) {
        this.midNum = 11;
        this.topSteps = stepsData;
      } else if (stepsData && stepsData.length > 11) {
        let n = 10;
        this.midNum = n;
        this.topSteps = stepsData.slice(0, n);
        //this.midSteps = stepsData[n]
        //this.bottomSteps = stepsData.slice(n + 1).reverse();
        stepsData.forEach((item, index) => {
          if (index % 10 === 0 && index != 0) {
            this.midSteps.push(stepsData[index]);
          } else if (index > 11) {
            let rows = Math.floor(index / n) - 1;
            if (rows % 2 === 0)
              this.bottomSteps[rows].unshift(stepsData[index]);
            else this.bottomSteps[rows].push(stepsData[index]);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.el-step.is-center .el-step__line {
  left: 0 !important;
  right: -100% !important;
}
.el-step.is-center:nth-of-type(1) .el-step__line:nth-of-type(1) {
  left: 50% !important;
  right: -100% !important;
}
.line {
  position: absolute;
  width: 500%;
  height: 5px;
  bottom: -5px;
  right: 40px;
  background-color: #c0c4cc;
}
.activated {
  color: #1d953f !important;
}
.ccp {
  color: #f56c6c !important;
}
.f16 {
  font-size: 16px;
}
.pa {
  position: absolute;
  left: 60px;
}
.pa-right {
  position: absolute;
  right: 60px;
}
.circle {
  width: 160px;
  height: 125px;
  //margin-top: 25px;
  border: 5px solid #c0c4cc;
  border-radius: 0 30% 30% 0/50%;
  border-left: none;
  position: relative;
  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    width: 36px;
    height: 36px;
    transform: translate(55%, -50%);
    background-color: #fff;
    border-radius: 50%;
    color: #c0c4cc;
    text-align: center;
    font-size: 12px;
    border: 8px solid #ffffff;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .tips {
      display: flex;
      position: absolute;
      right: 30px;
      font-size: 12px;
      color: #c0c4cc;
      width: 80px;
      padding-right: 8px;
      flex-direction: column;
      text-align: center;
      > span:first-child {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
.circle-left {
  width: 160px;
  height: 126px;
  //margin-top: 25px;
  border: 5px solid #c0c4cc;
  border-radius: 30% 0 0 30%/50%;
  border-right: none;
  position: relative;
  .icon {
    position: absolute;
    left: -40px;
    top: 50%;
    width: 36px;
    height: 36px;
    transform: translate(55%, -50%);
    background-color: #fff;
    border-radius: 50%;
    color: #c0c4cc;
    text-align: center;
    font-size: 12px;
    border: 8px solid #ffffff;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .tips {
      display: flex;
      position: absolute;
      left: 30px;
      font-size: 12px;
      color: #c0c4cc;
      width: 80px;
      padding-right: 8px;
      flex-direction: column;
      text-align: center;
      > span:first-child {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.custom-steps {
  padding: 20px;
  display: flex;
  .left-steps {
    width: calc(100% - 150px);
    /*float: left;*/
  }
  .left-steps-bottom {
    width: calc(100% - 150);
    margin-left: 150px;
  }
  .right-steps {
    /*float: right;*/
    margin-left: -50px;
    height: 130px;
  }
  /deep/.el-step__icon {
    /*background: #f6f6f6;*/
  }
  /deep/.el-step__icon-inner {
    font-size: 36px;
  }
  /deep/.el-step__line {
    background: transparent;
    border-top: 5px solid;
    .el-step__line-inner {
      border-width: 0 !important;
    }
  }
  /deep/.el-step {
    position: relative;
    padding: 15px 0;
    .el-step__head.is-process {
      color: #c0c4cc;
      border-color: #c0c4cc;
    }
    .el-step__title.is-process {
      color: #c0c4cc;
      font-weight: unset;
    }
  }
}
.record-bg {
  background-image: url("../assets/image/cool-background.png");
  overflow: hidden;
  border-radius: 20px;
  background-size: cover;
  z-index: 100;
  /*background-repeat: no-repeat;*/
}
.p50 {
  padding: 50px 20px !important;
  & > div {
    padding: 10px 0;
    border-top: 1px solid #7bbfea;
    display: flex;
    align-items: center;
  }
  &:last-child {
    padding: 0;
    border-bottom: 1px solid #7bbfea;
  }
}
.pointer {
  cursor: pointer;
}
.default {
  cursor: default;
}
/*#fade-box {*/
/*    animation: input-entry 3s ease-in;*/
/*}*/

$stark-light-blue: #00fffc;
$stark-med-blue: #00a4a2;
#fade-box {
  animation: form-entry 2s ease-in-out;
  /*background: #111;*/
  /*background: linear-gradient(#004746, #111);*/
  border: 1px solid $stark-med-blue;
  box-shadow: 0 0 15px #00fffd;
  border-radius: 20px;
  /*display: inline-block;*/
  /*height: 220px;*/
  /*margin: 200px auto 0;*/
  /*position: relative;*/
  z-index: 4;
  /*width: 500px;*/
  transition: 1s all;

  &:hover {
    border: 1px solid #00fffd;
    box-shadow: 0 0 25px #00fffd;
    transition: 1s all;
  }
}
@keyframes form-entry {
  0% {
    max-height: 80vh;
    width: 0;
    opacity: 0;
    padding: 0;
  }
  20% {
    max-height: 80vh;
    border: 4px solid $stark-med-blue;
    width: 0;
    opacity: 0;
    padding: 0;
  }

  40% {
    width: 0;
    max-height: 80vh;
    border: 4px solid $stark-med-blue;
    opacity: 1;
    padding: 0;
  }

  100% {
    max-height: 80vh;
    width: 800px;
  }
}
@keyframes input-entry {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.t-dialog {
  max-height: 70vh !important;
  overflow: scroll !important;
  width: 1200px !important;
}
.jcb {
  justify-content: space-between;
}
.more-box {
  padding: 5px 10px;
  border-radius: 8px;
}
.more-box:hover {
  background-color: #ededed;
}
.more-btn {
  width: 100%;
  padding: 5px 10px;
  // border-radius: 8px;
}
.more-btn:hover {
  background-color: #ededed;
}
.jcc {
  justify-content: center;
}
.mask-box {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.mask-container:hover .mask-box {
  display: flex;
}
</style>

