import { render, staticRenderFns } from "./CcpPoint .vue?vue&type=template&id=4cda0ddc&scoped=true&"
import script from "./CcpPoint .vue?vue&type=script&lang=js&"
export * from "./CcpPoint .vue?vue&type=script&lang=js&"
import style0 from "./CcpPoint .vue?vue&type=style&index=0&id=4cda0ddc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cda0ddc",
  null
  
)

export default component.exports