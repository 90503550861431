<template>
  <el-container class="shopList">
    <el-main class="p15 pt0">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="haccpQuery1" class="demo-form-inline">
          <el-form-item>
            <el-input
              v-model="haccpQuery1.keyword"
              placeholder="生产线名"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleHACCP('search')"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <div style="display: flex; padding: 20px 0">
        <div style="width: 300px; margin-right: 20px">
          <el-input
            v-model="haccpQuery1.keyword"
            placeholder="生产线名"
          ></el-input>
        </div>
        <div>
          <el-button type="primary" @click="handleHACCP('search')"
            >搜索</el-button
          >
        </div>
      </div> -->
      <el-table
        border
        fit
        :data="haccpList"
        empty-text="暂无数据"
        height="800"
        :stripe="true"
      >
        <el-table-column align="center" property="goodsName" label="生产线名">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建日期">
          <template slot-scope="scope">
            <span>{{ scope.row.createdTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="batchNumber"
          label="生产批次数"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.count }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" property="stock" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="goHACCPDetail(scope.row.id)"
              >查看详情</el-button
            >
            <el-button
              type="warning"
              style="margin-left: 10px"
              @click="goStatistics(scope.row.id)"
              >&emsp;统计&emsp;</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 20px; text-align: center">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="haccpTotal"
          :current-page="haccpCurrentPage"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
      <el-dialog
        title="HACCP"
        :visible.sync="showHACCPDetail"
        width="55%"
        @closed="
          openId = null;
          isCcp = true;
        "
      >
        <div>
          <div style="text-align: left; padding: 10px">
            <el-switch
              @change="switchChange"
              v-model="isCcp"
              active-text="仅含CCP点"
              inactive-text="全部"
            >
            </el-switch>
          </div>
          <el-table
            border
            fit
            :data="haccpDetailList"
            empty-text="暂无数据"
            height="800"
            :stripe="true"
            v-loading="detailLoading"
            ref="haccpDetailTable"
            :highlight-current-row="true"
            @expand-change="expandChanged"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <div v-if="scope.row.processPoint.length">
                  <custom-step
                    :companyId="companyId"
                    :haccpType="haccpType"
                    :steps-data="scope.row.processPoint"
                    :finishedTag="scope.row.finishedTag"
                    :active="1"
                    :isCcp="isCcp"
                  ></custom-step>
                </div>
                <div
                  v-else
                  class="df aic jcc"
                  style="padding: 20px; color: #bbb"
                >
                  暂无数据
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="goodsName"
              label="创建时间/绑码时间"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.createdTime"
                  placeholder="请选择"
                  @change="(value) => finishedTagChange(value, scope.row)"
                  @visible-change="(flag) => finishedTagList(flag, scope.row)"
                >
                  <el-option
                    v-for="item in scope.row.option"
                    :key="item.finishedTag"
                    :label="item.bindTime"
                    :value="item.bindTime"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" label="日期">
              <template slot-scope="scope">
                <span>{{ scope.row.createdTime.replace("T", " ") }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="batchNumber"
              label="HACCP点完成比"
            >
              <template slot-scope="scope">
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  :percentage="scope.row.finishingRate"
                  status="success"
                ></el-progress>
              </template>
            </el-table-column>
          </el-table>
          <div style="padding: 20px; text-align: center">
            <el-pagination
              background
              :page-size="haccpQuery2.limit"
              layout="total,prev, pager, next"
              :total="haccpDetailTotal"
              :current-page="haccpQuery2.page"
              @current-change="handleCateringHACCP"
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        v-if="showStatistics"
        title="统计"
        :visible.sync="showStatistics"
        width="55%"
      >
        <div class="scroll-box">
          <div>
            <div class="df" style="margin-left: 60px">
              <el-date-picker
                v-model="timeDate"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="dateChange"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
              <el-button
                type="warning"
                style="margin-left: 20px"
                @click="haccpShow = !haccpShow"
                >工序筛选</el-button
              >
              <el-button
                type="primary"
                style="margin-left: 20px"
                @click="searchStatistics"
                >&emsp;统计&emsp;</el-button
              >
            </div>
            <div :class="{ haccpShow: haccpShow, haccpBox: true }" style="">
              <custom-step-checked
                @checkChange="checkChange"
                :haccpShow="haccpShow"
                :steps-data="templateList"
              ></custom-step-checked>
            </div>
            <div v-if="!haccpShow">
              <el-table
                border
                fit
                :data="statisticsList"
                empty-text="暂无数据"
                height="600"
                :stripe="true"
                ref="haccpDetailTable"
              >
                <el-table-column align="center" property="name" label="批次">
                </el-table-column>
                <el-table-column
                  align="center"
                  property="createTime"
                  label="创建时间"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.createTime.replace("T", " ") }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  property="updatedTime"
                  label="更新时间"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.updatedTime
                        ? scope.row.updatedTime.replace("T", " ")
                        : ""
                    }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <div style="padding: 20px; text-align: center">
                <el-pagination
                  background
                  :page-size="statisticsQuery.limit"
                  layout="total,prev, pager, next"
                  :total="statisticsTotal"
                  :current-page="statisticsQuery.page"
                  @current-change="statisticsPageChange"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import axios from "../../../util/api";
import api from "../../../util/extra-api";
import CustomStep from "@/components/customStepCcp";
import customStepChecked from "@/components/customStepChecked";
import dayjs from "dayjs";

export default {
  components: {
    CustomStep,
    customStepChecked,
  },
  data() {
    return {
      openId: null,
      isCcp: true,
      haccpList: null,
      haccpDetailList: null,
      showHACCPDetail: false,
      haccpTotal: 0,
      haccpDetailTotal: 0,
      haccpCurrentPage: 1,
      haccpDetailCurrentPage: 1,
      companyId: null,
      haccpType: 0,
      haccpQuery1: {
        page: 1,
        limit: 10,
        keyword: "",
        companyId: null,
      },
      haccpQuery2: {
        page: 1,
        limit: 10,
        keyword: "",
        companyId: null,
      },
      templateList: [],
      showStatistics: false,
      // activeNames:0
      timeDate: null,
      haccpShow: false,
      statisticsQuery: {
        page: 1,
        limit: 10,
        startTime: "",
        endTime: "",
      },
      statisticsList: [],
      statisticsTotal: 0,
      detailLoading: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.companyId = Number(localStorage.getItem("company_id"));
    this.handleHACCP();
  },
  watch: {
    timeDate(newVal, oldVal) {
      // console.log(newVal, "测试");
      if (!newVal) {
        this.statisticsQuery.startTime = "";
        this.statisticsQuery.endTime = "";
      }
    },
  },
  computed: {
    templateIds() {
      let str = "";
      let length = this.templateList.filter((v) => v.checked).length;

      this.templateList
        .filter((v) => v.checked)
        .forEach((v, i) => {
          str += v.id;
          if (i < length - 1) {
            str += ",";
          }
        });
      return str;
    },
  },
  methods: {
    dateChange(e) {
      this.statisticsQuery.startTime = dayjs(e[0]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.statisticsQuery.endTime = dayjs(e[1]).format("YYYY-MM-DD HH:mm:ss");
    },
    searchStatistics() {
      this.statisticsQuery.page = 1;
      this.haccpShow = false;
      this.getHaccpList();
    },
    getHaccpList() {
      api
        .get(`/v1/pc/haccpScreen/hRecord`, {
          templateIds: this.templateIds,
          companyId: this.companyId,
          ...this.statisticsQuery,
        })
        .then((res) => {
          if (res.code === 200) {
            this.statisticsList = res.data;
            this.statisticsTotal = res.total;
          }
        });
    },
    checkChange(data) {
      this.$set(
        this.templateList.filter((v) => v.id === data.id)[0],
        "checked",
        !data.checked
      );
    },
    goStatistics(id) {
      api
        .get(`/v1/pc/haccpScreen/hList`, { productionLineId: id })
        .then((res) => {
          if (res.code === 200) {
            this.templateList = res.data.map((v) => {
              return {
                ...v,
                checked: true,
              };
            });
            this.statisticsQuery = {
              page: 1,
              limit: 10,
              startTime: "",
              endTime: "",
            };
            this.timeDate = null;
            this.getHaccpList();
            this.showStatistics = true;
          }
        });
    },
    switchChange() {
      this.haccpQuery2.page = 1;
      this.goHACCPDetail(this.openId);
    },
    expandChanged(row, flag) {
      if (flag.includes(row)) {
        flag.forEach((v) => {
          if (v.id !== row.id) {
            this.$refs.haccpDetailTable.toggleRowExpansion(v, false);
          }
        });
      }
    },
    finishedTagChange(value, data) {
      api
        .get("/v1/pc/haccpScreen/getHaccpRecord", {
          recordId: data.id,
          finishedTag: data.option.filter((v) => v.bindTime === value)[0]
            .finishedTag,
          type: this.isCcp ? 1 : 0,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$refs.haccpDetailTable.toggleRowExpansion(data, false);
            this.$set(data, "processPoint", res.data.processPoint);
            this.$set(data, "finishingRate", res.data.finishingRate);
            this.$set(
              data,
              "finishedTag",
              data.option.filter((v) => v.bindTime === value)[0].finishedTag
            );
          }
        });
    },
    finishedTagList(flag, data) {
      if (flag) {
        api
          .get("/v1/pc/haccpScreen/finishedTagList", { recordId: data.id })
          .then((res) => {
            if (res.code === 200) {
              this.$set(data, "option", res.data);
            }
          });
      }
    },
    goHACCPDetail(id) {
      this.openId = id;
      let data = {
        id,
        ...this.haccpQuery2,
        type: this.isCcp ? 1 : 0,
      };
      this.detailLoading = true;
      this.showHACCPDetail = true;

      api.get("/v1/pc/haccpScreen/haccpRecordList", data).then((res) => {
        if (res.code === 200) {
          this.haccpDetailTotal = res.total;
          this.haccpDetailList = res.data.map((v) => {
            return {
              ...v,
              option: [],
            };
          });
          this.detailLoading = false;
        }
      });
      //this.showHACCPDetail = true
    },
    handleHACCP(action) {
      if (action) {
        this.haccpQuery1.page = 1;
        this.haccpCurrentPage = 1;
      }
      this.haccpQuery1.companyId = this.companyId;
      api.get("/v1/pc/haccpScreen/proList", this.haccpQuery1).then((res) => {
        if (res.code === 200) {
          this.haccpList = res.data;
          this.haccpTotal = res.total;
          this.haccpType = 0;
        }
      });
      //this.showHACCP = true
    },
    currentChange(page) {
      this.haccpCurrentPage = page;
      this.haccpQuery1.page = page;
      this.handleHACCP();
    },
    statisticsPageChange(page) {
      this.statisticsQuery.page = page;
      this.getHaccpList();
    },
    handleCateringHACCP(page) {
      this.haccpQuery2.page = page;
      this.goHACCPDetail(this.openId);
      //this.showHACCP = true
    },
  },
};
</script>

<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
.jcc {
  justify-content: center;
}
.haccpShow {
  height: auto !important;
}
.haccpBox {
  overflow: hidden;
  margin-top: 10px;
  border-radius: 20px;
  height: 0;
  background-color: #f8f8f8;
}
.scroll-box {
  height: 800px;
  overflow-y: scroll;
  padding: 0 10px;
}
.scroll-box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.scroll-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // background: rgba(255, 255, 255, 0.8);
  background: #bbb;
  transition: color 0.2s ease;
  cursor: pointer;
}
</style>